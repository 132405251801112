import { ComponentWrapper } from '@cheese-fondue/components/componentWrapper/componentWrapper';
import { Box, Grid } from '@cheese-fondue/components/grid';
import { TwoColumnText } from '@cheese-fondue/components/twoColumnText';
import { COLORS } from '@cheese-fondue/styles';
import { PageProps } from 'gatsby';
import React, { ReactElement } from 'react';
import SmallHeaderLayout from '@cheese-fondue/layout/smallHeaderLayout';

const Page = (props: PageProps): ReactElement => {
  return (
    <SmallHeaderLayout location={props.location} pageName="Contact" whiteOnBlue>
      <Grid>
        <Box cols={[12]} className="mt-four mt-medium-six">
          <ComponentWrapper backgroundColorLeft={COLORS.white} backgroundColorRight={COLORS.white}>
            <TwoColumnText title="Contact">
              <h3>Address:</h3>
              <p>
                <address>
                  Delphia Technologies Inc.
                  <br />
                  200 Bay Street
                  <br />
                  North Tower, Suite 1200
                  <br />
                  Toronto ON M5J 2J2, Canada
                </address>
              </p>

              <h3>Contact support:</h3>
              <p>
                <a href="mailto:support@delphia.com">support@delphia.com</a>
              </p>
            </TwoColumnText>
          </ComponentWrapper>
        </Box>
      </Grid>
    </SmallHeaderLayout>
  );
};

export default Page;
