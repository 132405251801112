import { media } from '@cheese-fondue/helpers';
import { COLORS, SIZES } from '@cheese-fondue/styles/';
import { speckledBackground } from '@cheese-fondue/styles/backgrounds';
import React, { ReactElement, ReactNode } from 'react';
import styled from 'styled-components';

type SpeckleOptions = 'Left' | 'Right' | 'Both';

export type ComponentWrapperProps = {
  backgroundColorLeft: string;
  backgroundColorRight: string;
  speckles?: SpeckleOptions;
  children: ReactNode;
};

export const Wrapper = styled.div`
  position: relative;
  background-color: ${COLORS.white};
  display: flex;
`;

export const InnerWrapper = styled.div`
  position: relative;
  flex: none;
  width: 100%;
  max-width: ${SIZES.large}px;
  margin: 0 auto;

  ${media.large`
    width: 1200px;
  `}
`;

export const Background = styled.div<{ position: string; background: string; speckles?: SpeckleOptions }>`
  display: none;
  position: relative;
  width: 100%;
  ${({ background, speckles, position }) =>
    (background && !speckles) || (speckles && [position, 'Both'].indexOf(speckles) === -1)
      ? `background-color: ${background};`
      : ``};
  ${({ background, speckles, position }) =>
    background && speckles && [position, 'Both'].indexOf(speckles) > -1 ? speckledBackground(background) : ``};

  ${media.large`
    display: block;
  `}
`;

export const ComponentWrapper = ({
  backgroundColorLeft,
  backgroundColorRight,
  speckles,
  children,
  ...rest
}: ComponentWrapperProps): ReactElement => {
  return (
    <Wrapper {...rest}>
      <Background position="Left" speckles={speckles} background={backgroundColorLeft}></Background>
      <InnerWrapper>{children}</InnerWrapper>
      <Background position="Right" speckles={speckles} background={backgroundColorRight}></Background>
    </Wrapper>
  );
};
