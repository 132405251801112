import { graphql, Link, useStaticQuery } from 'gatsby';
import { FooterDarkInfoQuery, FooterDarkNavigationYaml } from 'graphql-types';
import React, { ReactElement, useMemo } from 'react';
import { ScreenReaderLabel } from '../../helpers/screen-reader-label';
import { DelphiaWordMarkWhite } from '../atoms';
import { Box, Grid } from '../grid';
import {
  Copyright,
  FooterContainer,
  FooterLegal,
  FooterMain,
  FooterWrapDark,
  LogoContainer,
  NavGrid,
  NavGridContainer,
  StyledLink,
  SecondRow,
  TopRow,
} from './footer-styles';

export interface PureFooterProps {
  data: FooterDarkInfoQuery;
  isVisible: boolean;
}

const renderNavItems = (nodes: FooterDarkInfoQuery['allFooterDarkNavigationYaml']['edges']): React.ReactNode => {
  return nodes.map((e: { node: Pick<FooterDarkNavigationYaml, 'id' | 'label' | 'path'> }) => {
    return (
      <Box cols={[1]} tag="li" key={e.node.id}>
        <StyledLink partiallyActive={true} activeClassName="active" to={e.node.path || '#'}>
          {e.node.label}
        </StyledLink>
      </Box>
    );
  });
};

export const FooterDark = (): ReactElement => {
  const data: FooterDarkInfoQuery = useStaticQuery(graphql`
    query FooterDarkInfo {
      allFooterDarkNavigationYaml {
        edges {
          node {
            id
            label
            path
          }
        }
      }
    }
  `);

  return <PureFooter data={data} />;
};

export const PureFooter = ({ data }: PureFooterProps): ReactElement => {
  const navDataEdge = useMemo(() => data.allFooterDarkNavigationYaml.edges, [data]);

  return (
    <FooterWrapDark id="footer-content" data-testid="footer">
      <FooterContainer theme="dark">
        <FooterMain>
          <TopRow theme="dark">
            <LogoContainer>
              <StyledLink to="/" title="Delphia.com">
                <DelphiaWordMarkWhite />
                <ScreenReaderLabel>Home Page</ScreenReaderLabel>
              </StyledLink>
            </LogoContainer>
            <NavGridContainer>
              <NavGrid theme="dark" tag="ul">
                {renderNavItems(navDataEdge)}
              </NavGrid>
            </NavGridContainer>
          </TopRow>
          <Grid colspan={[12]} className="grid">
            <Box cols={[12, 6, 6]} className="box">
              <SecondRow>
                <FooterLegal>
                  <ul>
                    <li>
                      <Link to="/terms-of-service">Terms of Service</Link>
                    </li>
                    <li>
                      <Link to="/privacy-policy">Privacy Policy</Link>
                    </li>
                  </ul>
                </FooterLegal>
                <Copyright>&copy; {new Date().getFullYear()} Delphia Technologies Inc. All rights reserved.</Copyright>
              </SecondRow>
            </Box>
          </Grid>
        </FooterMain>
      </FooterContainer>
    </FooterWrapDark>
  );
};
