import { SmallHeader } from '@cheese-fondue/components';
import { FooterDark } from '@cheese-fondue/components/footer/footerDark';
import GlobalStyle from '@cheese-fondue/styles';
import { THEME } from '@cheese-fondue/styles/theme';
import Head from '@components/head';
import { WindowLocation } from '@reach/router';
import '@static/styles/cheese_font_styles.css';
import React, { ReactElement } from 'react';
import { ThemeProvider } from 'styled-components';

interface LayoutProps {
  pageName: string;
  children: React.ReactNode;
  location: WindowLocation;
  queryData?: object;
  blackOnPurple?: boolean;
  whiteOnBlue?: boolean;
  whiteOnGreen?: boolean;
  blueOnOrange?: boolean;
  constrainedWidth?: boolean;
}

const SmallHeaderLayout = ({
  pageName,
  children,
  location,
  queryData,
  constrainedWidth,
  blackOnPurple = false,
  whiteOnBlue = false,
  whiteOnGreen = false,
  blueOnOrange = false,
}: LayoutProps): ReactElement => {
  return (
    <ThemeProvider theme={THEME}>
      <>
        <GlobalStyle />
        <Head pageName={pageName} location={location} pageContext={queryData} />
        <SmallHeader
          location={location}
          whiteOnBlue={whiteOnBlue}
          blueOnOrange={blueOnOrange}
          blackOnPurple={blackOnPurple}
          whiteOnGreen={whiteOnGreen}
        />
        <main id="main-content" {...(constrainedWidth ? { className: 'constrained' } : {})}>
          {children}
        </main>
        <div id="modal-root"></div>
        <FooterDark />
      </>
    </ThemeProvider>
  );
};

export default SmallHeaderLayout;
