import { media } from '@cheese-fondue/helpers';
import { speckledBackground } from '@cheese-fondue/styles/backgrounds';
import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { responsiveMedia } from '../../helpers/media-queries';
import { responsiveProp } from '../../helpers/responsiveProp';
import { COLORS, SPACING, SpacingVariants } from '../../styles/theme-constants';
import { typography } from '../../styles/typography';
import { ButtonLink } from '../atoms/button/button';
import { Box, BoxTypes, Grid } from '../grid';
import { AspectRatio } from '../grid/box';

export interface TwoColumnTextProps {
  strapline?: string;
  straplineColor?: string;
  title: string;
  titleColor?: string;
  textColor?: string;
  backgroundColor?: string;
  children: React.ReactNode;
  aspectRatio?: BoxTypes['aspectRatio'];
  pt?: SpacingVariants | SpacingVariants[];
  pb?: SpacingVariants | SpacingVariants[];
  cta?: {
    title: string;
    text: string;
    path: string;
  };
}

const Strapline = styled.h5<Partial<TwoColumnTextProps>>`
  color: ${({ straplineColor }) => (straplineColor ? straplineColor : COLORS.black)};
  margin: 0 0 ${SPACING.one}px;
  letter-spacing: 0.1em;
  font-weight: 400;
  line-height: 14px;
  ${typography.preSmall};

  padding: 0 ${SPACING.one}px;

  ${media.medium`
    padding: 0 ${SPACING.two}px;
  `}
`;
const Title = styled.h1<Partial<TwoColumnTextProps>>`
  color: ${({ titleColor }) => (titleColor ? titleColor : COLORS.black)};
  margin: 0;
  letter-spacing: -0.04em;
  line-height: 53px;
  font-weight: 500;
  ${typography.h1};

  ${media.customBreak(1000)`
    ${typography.h1XLarge};
  `}
`;

const Body = styled.div<{ textColor?: string }>`
  color: ${({ textColor = COLORS.blue400 }) => textColor};
  ${typography.p};

  p {
    ${typography.p};

    ${media.customBreak(1000)`
        ${typography.pMedium};
    `}
  }

  > :first-child {
    margin-top: 0;
  }

  > :last-child {
    margin-bottom: 0;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${SPACING.two}px;

  ${media.medium`
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: ${SPACING.three}px;    
  `}
`;

const Wrapper = styled.div<{
  backgroundColor?: string;
  pt?: SpacingVariants | SpacingVariants[];
  pb?: SpacingVariants | SpacingVariants[];
}>`
  ${({ backgroundColor }) => (backgroundColor ? speckledBackground(backgroundColor) : ``)};

  ${({ pt, pb }) =>
    responsiveMedia.map((media, i) => {
      return media`
    padding-top: ${pt && pt[i] ? pt[i] : 0}px;
    padding-bottom: ${pb && pb[i] ? pb[i] : 0}px;
  `;
    })}
`;

const StyledRightCol = styled.div`
  padding: 0 ${SPACING.one}px;

  ${media.medium`
    padding: 0 0;
  `}
`;

const StyledLeftCol = styled.div`
  padding: 0 ${SPACING.one}px;

  ${media.medium`
    padding: 0 0 0 ${SPACING.two}px;
  `}
`;

export const getMargin = (value?: SpacingVariants): nupber | undefined => {
  if (value && value in SPACING) {
    return SPACING[value];
  }
  return 0;
};

export const TwoColumnText = (props: TwoColumnTextProps): ReactElement => {
  const {
    strapline,
    title,
    children,
    cta,
    titleColor,
    textColor,
    straplineColor,
    backgroundColor,
    aspectRatio,
    pb = ['one', 'two'],
    pt = ['one', 'two'],
    ...rest
  } = props;

  const _aspectRatio = aspectRatio || [AspectRatio.Free, AspectRatio.OneByOne];
  const responsivept = responsiveProp({ prop: pt, mapper: val => getMargin(val) });
  const responsivepb = responsiveProp({ prop: pb, mapper: val => getMargin(val) });

  return (
    <Wrapper backgroundColor={backgroundColor} pt={responsivept} pb={responsivepb} {...rest}>
      {strapline && <Strapline straplineColor={straplineColor || titleColor}>{strapline}</Strapline>}
      <Grid alignY="top" gutterY={[`${SPACING.two}px`, '0']}>
        <Box cols={[12, 6]} aspectRatio={_aspectRatio}>
          {title && (
            <StyledLeftCol>
              <Title titleColor={titleColor}>{title}</Title>
            </StyledLeftCol>
          )}
        </Box>
        <Box cols={[0, 1]} />
        <Box cols={[12, 4]}>
          <StyledRightCol>
            <Body textColor={textColor}>{children}</Body>
            {cta && (
              <ButtonContainer>
                <ButtonLink to={cta.path} title={cta.title} target="_blank" rel="noopener">
                  {cta.text}
                </ButtonLink>
              </ButtonContainer>
            )}
          </StyledRightCol>
        </Box>
      </Grid>
    </Wrapper>
  );
};
